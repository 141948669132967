<template>
  <a-modal width="1520px" :title="title" :visible="visible" @cancel="handleCancel">
    <div class="actions flex jc-end mb-4">
      <!--       <a-button :disabled="!selectedRowKeys.length" @click="translate" :loading="btnLoadding" icon="diff" class="mr-3">
        机翻
      </a-button> -->
      <a-dropdown-button
        :disabled="!selectedRowKeys.length || btnLoadding"
        @click="btnLoadding ? () => {} : translate(0)"
        class="mr-3"
        :trigger="['click']"
      >
        <a-menu slot="overlay">
          <a-menu-item key="1" @click="translate(1)"> 切换机翻 </a-menu-item>
        </a-menu>
        <span>
          <a-icon type="diff" />
          机翻
        </span>
        <a-icon @click.stop type="down" slot="icon" />
      </a-dropdown-button>
      <!--       <a-button @click="ocr" :loading="btnLoadding" :disabled="!selectedRowKeys.length" icon="scan" type="primary">
        OCR
      </a-button> -->
      <a-dropdown-button
        :disabled="!selectedRowKeys.length || btnLoadding"
        @click="btnLoadding ? () => {} : ocr()"
        type="primary"
        :spinning="btnLoadding"
        class="mr-3"
        :trigger="['click']"
      >
        <a-menu slot="overlay">
          <!-- <a-menu-item @click="ocr" key="1">识别文本框</a-menu-item> -->
          <a-menu-item @click="noOcr" key="2">清除结果</a-menu-item>
        </a-menu>
        <span @click="ocr">
          <a-icon type="scan" />
          OCR
        </span>
        <a-icon type="down" @click.stop slot="icon" />
      </a-dropdown-button>
      <!--       <a-button type="primary">
        <span class="iconfont icon-duohangwenben icon"></span>
        读取
      </a-button> -->
    </div>
    <a-table
      :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange, columnWidth: 60 }"
      :columns="columns"
      :style="backCss"
      :loading="btnLoadding"
      rowKey="taskId"
      :customRow="clickRow"
      :scroll="{ x: '1380px', y: '500px' }"
      :data-source="dataSource"
    >
      <template slot="taskName" slot-scope="text, record">
        <span>
          {{ record.productName + '-' + record.chapterOrder + '-' + record.taskName }}
        </span>
      </template>
      <template slot="user" slot-scope="text, record">
        <div style="display: flex; align-items: center">
          <img style="width: 30px; height: 30px; border-radius: 50%; margin-right: 10px" :src="record.avatar" alt="" />
          {{ record.userName }}
        </div>
      </template>
      <template slot="completeStatus" slot-scope="text, record">
        <div @click.stop class="up-msg-status">
          <actions-tasks
            :propsParams="record"
            :isMyTask="true"
            :isMyTaskList="true"
            :isCalendar="true"
            :loadData="getTableData"
          ></actions-tasks>
        </div>
      </template>
      <template slot="imgNum" slot-scope="text, record">
        <div>
          <span class="img-num-title">原稿：</span
          ><!-- <span>{{ (record.originalManuscriptPSDStatus && record.originalManuscriptPSDStatus.count) || 0 }}</span> -->
          <span>{{ (record.manuscriptPSDList && record.manuscriptPSDList.length) || 0 }}</span>
        </div>
        <div>
          <span class="img-num-title">JPG：</span
          ><!-- <span>{{ (record.originalManuscriptJPGStatus && record.originalManuscriptJPGStatus.count) || 0 }}</span> -->
          <span>{{ (record.manuscriptJPGList && record.manuscriptJPGList.length) || 0 }}</span>
        </div>
      </template>
      <template slot="jd" slot-scope="text, record">
        <div><a-progress :percent="record.topProgress" :status="record.topStatus" /></div>
        <div><a-progress :percent="record.bottomProgress" :status="record.bottomStatus" /></div>
      </template>
      <template slot="cg" slot-scope="text, record">
        <div>{{ record.cgTopNum }}</div>
        <div>{{ record.cgBottomNum }}</div>
      </template>
      <template slot="sb" slot-scope="text, record">
        <div>{{ record.sbTopNum }}</div>
        <div>{{ record.sbBottomNum }}</div>
      </template>
      <template slot="textStatus" slot-scope="text, record">
        <div class="text-status">
          <span v-if="record.hasOcr"><a-icon type="check-circle" /><span style="margin-left: 3px">完成</span></span>
          <span v-else> <a-icon type="close-circle" /><span style="margin-left: 3px">未完成</span></span>
        </div>
      </template>
      <template slot="action" slot-scope="text, record">
        <div @click.stop>
          <!--           <a-upload
            name="file"
            accept=".jpg"
            :showUploadList="false"
            :multiple="true"
            :action="ossUploadUrl"
            :data="ossUploadData"
            :before-upload="beforeRightUpload"
            :disabled="record.originalManuscriptPSDStatus && record.originalManuscriptPSDStatus.count ? true : false"
            :custom-request="
              options => {
                handleUpload(options, 'PSD', record, 'original')
              }
            "
            @change="
              info => {
                handleChangeUpload(info, 'PSD', record)
              }
            "
          >
            <a
              @click="upClick(record)"
              :class="
                record.originalManuscriptPSDStatus && record.originalManuscriptPSDStatus.count ? 'disabled-up' : ''
              "
              >上传原稿</a
            >
          </a-upload> -->
          <defaultSizeUpload
            v-show="sizeList.length"
            :ref="'defaultSizeUpload-PSD-' + record.taskId"
            btnText="上传原稿"
            :isTable="true"
            upType="PSD"
            :taskDetail="record"
            :showFileList="record.manuscriptPSDList || []"
            @successProgress="file => successProgress(file, record, 'PSD')"
            @startProgress="
              (fileList, finishFileList, upType) => {
                startProgress(fileList, finishFileList, upType, record)
              }
            "
            @changeProgress="
              (fileList, finishFileList, upType, isSuccess) => {
                changeProgress(fileList, finishFileList, upType, record, isSuccess)
              }
            "
            @changeTransProgress="
              (fileList, upType) => {
                changeTransProgress(fileList, upType, record)
              }
            "
            @endProgress="
              upType => {
                endProgress(upType, record)
              }
            "
            btnType="original"
            :sizeList="sizeList"
            :loadData="() => {}"
            saveUrl="/kt/translate/uploadOriginalManuscript"
          />
        </div>
        <div @click.stop>
          <!--           <a-upload
            name="file"
            accept=".jpg"
            :showUploadList="false"
            :multiple="true"
            :action="ossUploadUrl"
            :data="ossUploadData"
            :before-upload="beforeRightUpload"
            :disabled="record.originalManuscriptJPGStatus && record.originalManuscriptJPGStatus.count ? true : false"
            :custom-request="
              options => {
                handleUpload(options, 'JPG', record, 'jpg')
              }
            "
            @change="
              info => {
                handleChangeUpload(info, 'JPG', record)
              }
            "
          >
            <a
              @click="upClick(record)"
              :class="
                record.originalManuscriptJPGStatus && record.originalManuscriptJPGStatus.count ? 'disabled-up' : ''
              "
              >上传JPG</a
            >
          </a-upload> -->
          <defaultSizeUpload
            v-show="sizeList.length"
            btnText="上传JPG"
            :ref="'defaultSizeUpload-JPG-' + record.taskId"
            :isTable="true"
            upType="JPG"
            :taskDetail="record"
            :showFileList="record.manuscriptJPGList || []"
            @successProgress="file => successProgress(file, record, 'JPG')"
            @startProgress="
              (fileList, finishFileList, upType) => {
                startProgress(fileList, finishFileList, upType, record)
              }
            "
            @changeProgress="
              (fileList, finishFileList, upType, isSuccess) => {
                changeProgress(fileList, finishFileList, upType, record, isSuccess)
              }
            "
            @changeTransProgress="
              (fileList, upType) => {
                changeTransProgress(fileList, upType, record)
              }
            "
            @endProgress="
              upType => {
                endProgress(upType, record)
              }
            "
            btnType="original"
            :sizeList="sizeList"
            :loadData="() => {}"
            saveUrl="/kt/translate/uploadOriginalManuscript"
          />
        </div>
      </template>
    </a-table>
    <template slot="footer">
      <a-button key="back" @click="handleCancel"> 关闭 </a-button>
    </template>
  </a-modal>
</template>

<script>
import ActionsTasks from '@/views/system/modules/mytasks/actionsTasksBtn'
import { UploadFileToOssMixins } from '@/mixins/UploadFileToOssMixins'
import { postAction } from '@/api/manage'
import { upload } from '@/utils/upload'
import { getAction } from '../../api/manage'
import { handleArrayParams } from '@/utils/util'
import defaultSizeUpload from '@/components/default-size-upload'
export default {
  mixins: [UploadFileToOssMixins],
  props: {
    selectionRows: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: '上传原稿'
    }
  },
  components: {
    ActionsTasks,
    defaultSizeUpload
  },
  data() {
    return {
      visible: false,
      dataSource: [],
      sizeList: [],
      columns: [
        {
          title: '任务名称',
          dataIndex: 'taskName',
          width: 100,
          scopedSlots: { customRender: 'taskName' }
        },
        {
          title: '人员',
          dataIndex: 'user',
          scopedSlots: { customRender: 'user' },
          width: 60
        },
        {
          title: '完成状态',
          dataIndex: 'completeStatus',
          width: 80,
          scopedSlots: { customRender: 'completeStatus' }
        },
        {
          title: '图片数量',
          dataIndex: 'imgNum',
          scopedSlots: { customRender: 'imgNum' },
          width: 60
        },
        {
          title: '进度',
          dataIndex: 'jd',
          scopedSlots: { customRender: 'jd' },
          width: 100
        },
        /*         {
          title: '成功',
          dataIndex: 'cg',
          scopedSlots: { customRender: 'cg' },
          width: 100
        },
        {
          title: '失败',
          dataIndex: 'sb',
          scopedSlots: { customRender: 'sb' },
          width: 100
        }, */
        {
          title: 'OCR状态',
          dataIndex: 'textStatus',
          scopedSlots: { customRender: 'textStatus' },
          width: 60
        },
        {
          title: '修改时间',
          dataIndex: 'lastModified',
          width: 100
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
          width: 60
        }
      ],
      clickRow: (record, index) => ({
        on: {
          click: () => {
            this.gotoDetail(record)
          }
        }
      }),
      color: '',
      selectedRowKeys: [],
      bottomProgress: 0,
      topProgress: 0,
      progres: 0,
      rightTransNum: 1,
      leftTransNum: 1,
      uploadFileList: [],
      selections: [],
      topStatus: 'active',
      bottomStatus: 'active',
      btnLoadding: false
    }
  },
  created() {},
  mounted() {
    setTimeout(() => {
      for (let css of document.styleSheets) {
        for (let rule of css.cssRules) {
          if (rule.cssText?.includes('.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected')) {
            this.color = rule.style.backgroundColor
          }
        }
      }
    }, 100)
  },
  computed: {
    backCss() {
      let color = this.color
      let backColor = this.$store.state.app.color
      return {
        '--back-color': color,
        '--theme-color': backColor
      }
    }
  },
  methods: {
    startProgress(fileList, finishFileList, upType, record) {
      if (!fileList.length) return
      if (upType == 'JPG') {
        record.bottomStatus = 'active'
        record.bottomProgress = 0
        record.rightTransNum = 1
        if (record.bottomProgress < 80) return
        record.bottomProgress = ((finishFileList.length / fileList.length) * 20).toFixed(0) - 0 + 80
      } else {
        record.topStatus = 'active'
        record.topProgress = 0
        record.leftTransNum = 1
        if (record.topProgress < 80) return
        record.topProgress = ((finishFileList.length / fileList.length) * 20).toFixed(0) - 0 + 80
      }
    },
    changeProgress(fileList, finishFileList, upType, record, isSuccess) {
      if (!fileList.length) return
      if (!isSuccess) {
        if (upType == 'PSD') {
          record.sbTopNum++
        } else {
          record.sbBottomNum++
        }
        return
      }
      if (upType == 'JPG') {
        if (record.bottomProgress < 80) return
        record.bottomProgress = ((finishFileList.length / fileList.length) * 20).toFixed(0) - 0 + 80
      } else {
        if (record.topProgress < 80) return
        record.topProgress = ((finishFileList.length / fileList.length) * 20).toFixed(0) - 0 + 80
      }
    },
    changeTransProgress(fileList, upType, record) {
      if (!fileList.length) return
      if (upType == 'JPG') {
        record.bottomProgress = ((record.rightTransNum / fileList.length) * 80).toFixed(0) - 0
        record.rightTransNum++
        if (record.bottomProgress > 78) {
          record.bottomProgress = 80
        }
      } else {
        record.topProgress = ((record.leftTransNum / fileList.length) * 80).toFixed(0) - 0
        record.leftTransNum++
        if (record.topProgress > 78) {
          record.topProgress = 80
        }
      }
    },
    endProgress(upType, record) {
      if (upType == 'JPG') {
        /*         if (record.originalManuscriptJPGStatus && record.originalManuscriptJPGStatus.failedCount) {
          record.bottomStatus = 'exception'
          record.bottomProgress = 99
        } else {
          record.bottomStatus = 'success'
          record.bottomProgress = 100
        } */
        record.bottomStatus = 'success'
        record.cgBottomNum++
        record.bottomProgress = 100
      } else {
        /*  if (record.originalManuscriptPSDStatus && record.originalManuscriptPSDStatus.failedCount) {
          record.topStatus = 'exception'
          record.topProgress = 99
        } else {
          record.topStatus = 'success'
          record.topProgress = 100
        } */
        record.topStatus = 'success'
        record.cgTopNum++
        record.topProgress = 100
      }
      /*       this.$forceUpdate()
      setTimeout(() => {
        this.getTableData()
      }, 1000) */
    },
    successProgress(files, record, type) {
      if (type == 'JPG') {
        if(!record.manuscriptJPGList) record.manuscriptJPGList = []
        files?.forEach(file=>{
          record.manuscriptJPGList.push(file)
        })
        record.bottomProgress = 0
        record.bottomStatus = 'active'
      } else {
        if(!record.manuscriptPSDList) record.manuscriptPSDList = []
        files?.forEach(file=>{
        record.manuscriptPSDList.push(file)
        })
        record.topProgress = 0
        record.topStatus = 'active'
      }
      record.rightTransNum = 1
      record.leftTransNum = 1
      record.cgTopNum = 0
      record.cgBottomNum = 0
      record.sbTopNum = 0
      record.sbBottomNum = 0
    },
    upClick(record) {
      record.topProgress = 0
      this.uploadFileList = []
    },
    async ocr() {
      let ids = []
      if (this.selections.length) {
        let selectionJpgRows = []
        let selectionPsdRows = []
        let selections = this.dataSource.filter((item, index) => {
          return this.selectedRowKeys.some(i => i == item.taskId)
        })
        selections.forEach(item => {
          if (item.manuscriptJPGList && item.manuscriptJPGList.length) {
            selectionJpgRows = [...selectionJpgRows, ...item.manuscriptJPGList]
          }
          if (item.manuscriptPSDList && item.manuscriptPSDList.length) {
            selectionPsdRows = [...selectionPsdRows, ...item.manuscriptPSDList]
          }
        })
        ids = [
          ...selectionJpgRows
            .filter(item => !item.ocrInfoList || (item.ocrInfoList && !item.ocrInfoList.length))
            .map(item => item.id),
          ...selectionPsdRows
            .filter(item => !item.ocrInfoList || (item.ocrInfoList && !item.ocrInfoList.length))
            .map(item => item.id)
        ]
      } else {
        let selectionJpgRows = []
        let selectionPsdRows = []
        this.dataSource.forEach(item => {
          if (item.manuscriptJPGList && item.manuscriptJPGList.length) {
            selectionJpgRows = [...selectionJpgRows, ...item.manuscriptJPGList]
          }
          if (item.manuscriptPSDList && item.manuscriptPSDList.length) {
            selectionPsdRows = [...selectionPsdRows, ...item.manuscriptPSDList]
          }
        })
        ids = [
          ...selectionJpgRows
            .filter(item => !item.ocrInfoList || (item.ocrInfoList && !item.ocrInfoList.length))
            .map(item => item.id),
          ...selectionPsdRows
            .filter(item => !item.ocrInfoList || (item.ocrInfoList && !item.ocrInfoList.length))
            .map(item => item.id)
        ]
      }
      if (!ids.length) {
        this.$message.error('没有需要OCR的数据')
        this.btnLoadding = false
        return
      }
      this.btnLoadding = true
      const res = await postAction('/kt/translate/originalManuscript/ocr', {
        ids: [],
        chapterIds: this.selections.map(item => item.chapterId)
      })
      if (res.code == 200) {
        this.$message.success('识别成功')
        this.getTableData()
      } else {
        this.$message.error(res.msg)
      }
      this.btnLoadding = false
    },
    async noOcr() {
      let originalManuscriptIds = []
      if (this.selections.length) {
        let selectionJpgRows = []
        let selectionPsdRows = []
        let selections = this.dataSource.filter((item, index) => {
          return this.selectedRowKeys.some(i => i == item.taskId)
        })
        selections.forEach(item => {
          if (item.manuscriptJPGList && item.manuscriptJPGList.length) {
            selectionJpgRows = [...selectionJpgRows, ...item.manuscriptJPGList]
          }
          if (item.manuscriptPSDList && item.manuscriptPSDList.length) {
            selectionPsdRows = [...selectionPsdRows, ...item.manuscriptPSDList]
          }
        })
        originalManuscriptIds = [...selectionJpgRows.map(item => item.id), ...selectionPsdRows.map(item => item.id)]
      } else {
        let selectionJpgRows = []
        let selectionPsdRows = []
        this.dataSource.forEach(item => {
          if (item.manuscriptJPGList && item.manuscriptJPGList.length) {
            selectionJpgRows = [...selectionJpgRows, ...item.manuscriptJPGList]
          }
          if (item.manuscriptPSDList && item.manuscriptPSDList.length) {
            selectionPsdRows = [...selectionPsdRows, ...item.manuscriptPSDList]
          }
        })
        originalManuscriptIds = [...selectionJpgRows.map(item => item.id), ...selectionPsdRows.map(item => item.id)]
      }
      let that = this
      this.$confirm({
        title: '提示',
        content: '确认清除？',
        async onOk() {
          const res = await postAction('/clear/ocr', {
            originalManuscriptIds,
            chapterIds: that.selections.map(item => item.chapterId)
          })
          if (res.code == 200) {
            that.$message.success('清除成功')
            that.getTableData()
          } else {
            that.$message.error(res.msg)
          }
        },
        onCancel() {}
      })
    },
    async translate(type) {
      this.btnLoadding = true
      let checkIds = []
      let ocrCheckList = []
      let ids = []
      let selections = this.dataSource.filter((item, index) => {
        return this.selectedRowKeys.some(i => i == item.taskId)
      })
      selections.forEach(item => {
        /*         if (item.manuscriptJPGList && item.manuscriptJPGList.length) {
          ocrCheckList = [...ocrCheckList, ...item.manuscriptJPGList]
        } */
        if (item.manuscriptPSDList && item.manuscriptPSDList.length) {
          ocrCheckList = [...ocrCheckList, ...item.manuscriptPSDList]
        }
      })
      checkIds = [...ocrCheckList.filter(item => !item.ocrInfoList).map(item => item.id)]
      ids = [...ocrCheckList.filter(item => item.ocrInfoList).map(item => item.id)]
      if (checkIds.length) {
        this.$message.error('有文件没有OCR，请重新操作OCR后再试')
        this.btnLoadding = false
        return
      }
      const res = await postAction('/kt/translate/originalManuscript/translate', {
        chapterIds: this.selections.map(item => item.chapterId),
        operator: type,
        ids
      })
      if (res.code == 200) {
        this.$message.success('翻译成功')
      } else {
        this.$message.error(res.msg)
      }
      this.btnLoadding = false
    },
    formatDate(datetime) {
      if (datetime != null) {
        const dateMat = new Date(datetime)
        const year = dateMat.getFullYear()
        const month = dateMat.getMonth() + 1
        const day = dateMat.getDate()
        const hh = dateMat.getHours()
        const mm = dateMat.getMinutes()
        const ss = dateMat.getSeconds()
        const timeFormat =
          year +
          '-' +
          (month < 10 ? '0' : '') +
          month +
          '-' +
          (day < 10 ? '0' : '') +
          day +
          ' ' +
          (hh < 10 ? '0' : '') +
          hh +
          ':' +
          (mm < 10 ? '0' : '') +
          mm +
          ':' +
          (ss < 10 ? '0' : '') +
          ss
        return timeFormat
      }
    },
    changeJpgErrorNum(record) {
      if (record.originalManuscriptJPGStatus && record.originalManuscriptJPGStatus.failedCount) {
        record.originalManuscriptJPGStatus.failedCount = record.originalManuscriptJPGStatus.failedCount + 1
      } else {
        record.originalManuscriptJPGStatus.failedCount = 1
      }
      record.bottomProgress = 99
      record.bottomStatus = 'exception'
    },
    changePsdErrorNum(record) {
      if (record.originalManuscriptPSDStatus && record.originalManuscriptPSDStatus.failedCount) {
        record.originalManuscriptPSDStatus.failedCount = record.originalManuscriptPSDStatus.failedCount + 1
      } else {
        record.originalManuscriptPSDStatus.failedCount = 1
      }
      record.topProgress = 99
      record.topStatus = 'exception'
    },
    async handleUpload(options, type, record, upType) {
      /*       if (upType == 'original') {
        this.topProgress = 0
      } else {
        this.bottomProgress = 0
      } */
      let formData = new FormData(),
        file = options.file
      for (let key in this.ossUploadData) {
        formData.append(key, this.ossUploadData[key])
      }
      formData.append('file', file)
      let data = new FormData()
      let dpi = 0
      data.append('files', file)
      if (upType == 'original') {
        this.progres = 0
        let timer = setInterval(() => {
          this.progres++
          record.topProgress = (this.progres / 10).toFixed(2) - 0
          if (this.progres >= 100) {
            clearTimeout(timer)
          }
        }, 15)
      } else {
        this.progres = 0
        let timer = setInterval(() => {
          this.progres++
          record.bottomProgress = (this.progres / 10).toFixed(2) - 0
          if (this.progres >= 100) {
            clearTimeout(timer)
          }
        }, 15)
      }
      const rest = await postAction('/kt/translate/getDpi', data)
      this.progres = 100
      if (rest.code == 200 && rest.data) {
        dpi = rest.data[0]
      } else if (rest.code != 200) {
        if (upType == 'original') {
          this.topStatus = 'exception'
        } else {
          this.bottomStatus = 'exception'
        }
        return
      }
      let index = this.uploadFileList.push(file) - 1
      upload(this.ossUploadData.host, formData, percent =>
        this.setUploadProcess(percent, file, index, upType, record)
      ).then(
        async () => {
          //成功处理
          Object.assign(file, { status: 'done' })
          this.uploadFileList.splice(index, 1, file)
          let params = this.getSaveFileParams(file)
          let that = this
          var read = new FileReader()
          read.readAsDataURL(file)
          read.onload = function(e) {
            var img = new Image()
            img.src = e.target.result
            let size = null
            size = that.sizeList.filter(item => item.productionId == record.productionId)[0]
            if (!size) {
              this.$message.error('未获取到默认尺寸，无法上传图片')
              return
            }
            img.onload = async function() {
              /*               if (upType == 'original') {
                if (size.size.psdFormat.indexOf(type) == -1) {
                  that.$message.error('格式错误')
                  that.uploadFileList.splice(index, 1, file)
                  that.changePsdErrorNum(record)
                  that.avatarLoading = false
                  return
                } else if (this.height > size.size.psdMaxHeight || this.height < size.size.psdMinHeight) {
                  that.$message.error('高度错误')
                  that.uploadFileList.splice(index, 1, file)
                  that.changePsdErrorNum(record)
                  that.avatarLoading = false
                  return
                } else if (this.width != size.size.psdWidth) {
                  that.$message.error('宽度错误')
                  that.uploadFileList.splice(index, 1, file)
                  that.changePsdErrorNum(record)
                  that.avatarLoading = false
                  return
                } else if (dpi != size.size.psdResolution) {
                  that.$message.error('分辨率错误')
                  that.uploadFileList.splice(index, 1, file)
                  that.changePsdErrorNum(record)
                  that.avatarLoading = false
                  return
                }
              } else {
                if (size.size.jpgFormat.indexOf(type) == -1) {
                  that.$message.error('格式错误')
                  that.uploadFileList.splice(index, 1, file)
                  that.changeJpgErrorNum(record)
                  that.avatarLoading = false
                  return
                } else if (this.height > size.size.jpgMaxHeight || this.height < size.size.jpgMinHeight) {
                  that.$message.error('高度错误')
                  that.uploadFileList.splice(index, 1, file)
                  that.changeJpgErrorNum(record)
                  that.avatarLoading = false
                  return
                } else if (this.width != size.size.jpgWidth) {
                  console.log(this.width, size.size.jpgWidth, 123)
                  that.$message.error('宽度错误')
                  that.uploadFileList.splice(index, 1, file)
                  that.changeJpgErrorNum(record)
                  that.avatarLoading = false
                  return
                } else if (dpi != size.size.jpgResolution) {
                  that.$message.error('分辨率错误')
                  that.uploadFileList.splice(index, 1, file)
                  that.changeJpgErrorNum(record)
                  that.avatarLoading = false
                  return
                }
              } */
              if (size.size.psdFormat.indexOf(type) == -1) {
                that.$message.error('格式错误')
                that.uploadFileList.splice(index, 1, file)
                that.changePsdErrorNum(record)
                that.avatarLoading = false
                return
              } else if (this.height > size.size.psdMaxHeight || this.height < size.size.psdMinHeight) {
                that.$message.error('高度错误')
                that.uploadFileList.splice(index, 1, file)
                that.changePsdErrorNum(record)
                that.avatarLoading = false
                return
              } else if (this.width != size.size.psdWidth) {
                that.$message.error('宽度错误')
                that.uploadFileList.splice(index, 1, file)
                that.changePsdErrorNum(record)
                that.avatarLoading = false
                return
              } else if (dpi != size.size.psdResolution) {
                that.$message.error('分辨率错误')
                that.uploadFileList.splice(index, 1, file)
                that.changePsdErrorNum(record)
                that.avatarLoading = false
                return
              }
              // 默认按比例压缩
              let data = {
                chapterId: record.chapterId,
                dataList: [
                  {
                    fileName: file.name,
                    filePath: params.filePath,
                    fileType: type,
                    wasSuccess: true,
                    dpi,
                    height: this.height,
                    width: this.width,
                    fileLength: file.size,
                    lastModified: that.formatDate(file.lastModifiedDate)
                  }
                ]
              }
              const res = await postAction('/kt/translate/uploadOriginalManuscript', data)

              if (res.code == 200) {
                if (type == 'JPG') {
                  if (record.originalManuscriptJPGStatus && record.originalManuscriptJPGStatus.successCount) {
                    record.originalManuscriptJPGStatus.successCount =
                      record.originalManuscriptJPGStatus.successCount + 1
                  } else {
                    record.originalManuscriptJPGStatus.successCount = 1
                  }
                  if (record.originalManuscriptJPGStatus && record.originalManuscriptJPGStatus.count) {
                    record.originalManuscriptJPGStatus.count = record.originalManuscriptJPGStatus.count + 1
                  } else {
                    record.originalManuscriptJPGStatus.count = 1
                  }
                } else {
                  if (record.originalManuscriptPSDStatus && record.originalManuscriptPSDStatus.successCount) {
                    record.originalManuscriptPSDStatus.successCount =
                      record.originalManuscriptPSDStatus.successCount + 1
                  } else {
                    record.originalManuscriptPSDStatus.successCount = 1
                  }
                  if (record.originalManuscriptPSDStatus && record.originalManuscriptPSDStatus.count) {
                    record.originalManuscriptPSDStatus.count = record.originalManuscriptPSDStatus.count + 1
                  } else {
                    record.originalManuscriptPSDStatus.count = 1
                  }
                }
                if (upType == 'original') {
                  that.topProgress = 100
                  if (record.topFileList) {
                    record.topFileList.push(file)
                  } else {
                    record.topFileList = []
                    record.topFileList.push(file)
                  }
                  record.topStatus = 'success'
                } else {
                  record.bottomProgress = 100
                  record.bottomStatus = 'success'
                }
                that.$message.success(file.name + '上传成功')
              }
              that.removeOssFilePolicy(file)
              that.avatarLoading = false
            }
          }
        },
        err => {
          //失败处理
          Object.assign(file, { status: 'error', message: '上传失败' })
          this.uploadFileList.splice(index, 1, file)
          if (type == 'JPG') {
            if (record.originalManuscriptJPGStatus && record.originalManuscriptJPGStatus.failedCount) {
              record.originalManuscriptJPGStatus.failedCount = record.originalManuscriptJPGStatus.failedCount + 1
            } else {
              record.originalManuscriptJPGStatus.failedCount = 1
            }
          } else {
            if (record.originalManuscriptPSDStatus && record.originalManuscriptPSDStatus.failedCount) {
              record.originalManuscriptPSDStatus.failedCount = record.originalManuscriptPSDStatus.failedCount + 1
            } else {
              record.originalManuscriptPSDStatus.failedCount = 1
            }
          }
          if (upType == 'original') {
            record.topProgress = 99
            record.topStatus = 'exception'
          } else {
            record.bottomProgress = 99
            record.bottomStatus = 'exception'
          }
        }
      )
    },
    setUploadProcess(percent, file, index, upType, record) {
      Object.assign(file, { percent })
      this.uploadFileList.splice(index, 1, file)
      let num = 0
      this.uploadFileList.forEach(item => {
        num = num + item.percent
      })
      if (upType == 'original') {
        let progres = (num / this.uploadFileList.length).toFixed(2) - 0 + 10
        if (progres > 99 || progres == 100) {
          progres = 99
        }
        record.topProgress = progres
      } else {
        let progres = (num / this.uploadFileList.length).toFixed(2) - 0 + 10
        if (progres > 99 || progres == 100) {
          progres = 99
        }
        record.bottomProgress = progres
      }
    },
    async handleChangeUpload(info, type, record) {
      if (info.file.status === 'uploading') {
        this.avatarLoading = true
        return
      }
      if (info.file.status === 'done') {
      }
    },
    async beforeRightUpload(file) {
      return this.ossPolicy(file.name)
    },
    ossPolicy(fileName) {
      let _this = this
      return this.tokenAxios({
        url: '/oss',
        method: 'get'
      }).then(res => {
        let policy = {
          policy: res.data.policy,
          signature: res.data.signature,
          ossaccessKeyId: res.data.accessid,
          dir: res.data.dir,
          host: res.data.host,
          key: `${res.data.dir}${this.getGuid()}_${fileName.replace(/[^\u4e00-\u9fa5a-zA-Z0-9\w]/g, '')}`
        }
        _this.ossUploadData = policy
        _this.ossFilePolicy[fileName] = policy
      })
    },
    getGuid() {
      return `${this.S4() + this.S4()}-${this.S4()}-${this.S4()}-${this.S4()}-${this.S4()}${this.S4()}${this.S4()}`
    },
    onSelectChange(selectedRowKeys, selections) {
      this.selectedRowKeys = selectedRowKeys
      this.selections = selections
    },
    async open() {
      this.visible = true
      this.selectionRows.forEach(item => {
        if (item.originalManuscriptPSDStatus && item.originalManuscriptPSDStatus.failedCount) {
          item.originalManuscriptPSDStatus.failedCount = 0
        }
        if (item.originalManuscriptJPGStatus && item.originalManuscriptJPGStatus.failedCount) {
          item.originalManuscriptJPGStatus.failedCount = 0
        }
        item.topProgress = 0
        item.bottomProgress = 0
        item.rightTransNum = 1
        item.leftTransNum = 1
        item.topStatus = 'active'
        item.bottomStatus = 'active'
      })
      this.getTableData()
      this.sizeList = []
      const res = await getAction(
        '/kt/translate/manuscript/specification?' +
          new URLSearchParams(
            handleArrayParams({ productIds: this.selectionRows.map(item => item.productionId) })
          ).toString()
      )
      if (res.code == 200) {
        let sizeList = res.data
        setTimeout(() => {
          this.dataSource.forEach(record => {
            if (this.$refs['defaultSizeUpload-PSD-' + record.taskId]) {
              this.$refs['defaultSizeUpload-PSD-' + record.taskId].defaultMaxHeight = sizeList[0].size.psdMaxHeight
              this.$refs['defaultSizeUpload-PSD-' + record.taskId].defaultMinHeight = sizeList[0].size.psdMinHeight
              this.$refs['defaultSizeUpload-PSD-' + record.taskId].defaultWidth = sizeList[0].size.psdWidth
              this.$refs['defaultSizeUpload-PSD-' + record.taskId].defaultType = sizeList[0].size.psdFormat
              this.$refs['defaultSizeUpload-PSD-' + record.taskId].defaultDpi = sizeList[0].size.psdResolution
            }
            if (this.$refs['defaultSizeUpload-JPG-' + record.taskId]) {
              this.$refs['defaultSizeUpload-JPG-' + record.taskId].defaultMaxHeight = sizeList[0].size.psdMaxHeight
              this.$refs['defaultSizeUpload-JPG-' + record.taskId].defaultMinHeight = sizeList[0].size.psdMinHeight
              this.$refs['defaultSizeUpload-JPG-' + record.taskId].defaultWidth = sizeList[0].size.psdWidth
              this.$refs['defaultSizeUpload-JPG-' + record.taskId].defaultType = sizeList[0].size.psdFormat
              this.$refs['defaultSizeUpload-JPG-' + record.taskId].defaultDpi = sizeList[0].size.psdResolution
            }
          })
          this.sizeList = sizeList
        }, 100)
        if (!sizeList.length) {
          this.$message.error('未获取到默认尺寸，无法上传图片')
        }
      } else {
        this.$message.error('未获取到默认尺寸，无法上传图片')
      }
    },
    async handleOk() {
      this.visible = false
    },
    async getTableData() {
      let params = []
      this.selectionRows.forEach(item => {
        if (params.every(p => p.productionId != item.productionId)) {
          params.push({
            productionId: item.productionId,
            chapterId: []
          })
        }
      })
      this.selectionRows.forEach(item => {
        params.forEach(p => {
          if (item.productionId == p.productionId) {
            p.chapterId.push(item.chapterId)
          }
        })
      })
      const rest = await postAction('/original/getOriginalManuscript', params)
      if (rest.code == 200) {
        this.selectedRowKeys = []
        this.dataSource = rest.data.map(item => {
          let ocrIds = []
          let list = []
          let JpgList = []
          let psdList = []
          if (item.manuscriptJPGList) {
            JpgList = item.manuscriptJPGList || []
          }
          if (item.manuscriptPSDList) {
            psdList = item.manuscriptPSDList || []
          }
          list = [...JpgList, ...psdList]
          ocrIds = list.filter(item => !item.ocrInfoList).map(item => item.id)
          /*           ocrIds = [
            ...item.manuscriptJPGList.filter(item => !item.ocrInfoList).map(item => item.id),
            ...item.manuscriptPSDList.filter(item => !item.ocrInfoList).map(item => item.id)
          ] */
          return {
            ...item.task,
            stageName: item.task.taskName,
            taskStatus: item.task.taskStatuss,
            productName: item.production.productionName,
            hasOcr: ocrIds.length || (!JpgList.length && !psdList.length) ? false : true,
            topProgress: 0,
            bottomProgress: 0,
            topStatus: 'active',
            bottomStatus: 'active',
            rightTransNum: 1,
            leftTransNum: 1,
            cgTopNum: 0,
            cgBottomNum: 0,
            sbTopNum: 0,
            sbBottomNum: 0,
            manuscriptJPGList: item.manuscriptJPGList,
            manuscriptPSDList: item.manuscriptPSDList,
            userName: item.user.nickName,
            avatar: item.user.avatar,
            lastModified: item.task.lastModified
          }
        })
      }
    },
    handleCancel() {
      this.visible = false
      this.selectionRows.forEach(item => {
        item.topProgress = 0
        item.bottomProgress = 0
        item.topStatus = 'active'
        item.bottomStatus = 'active'
      })
      this.dataSource = []
      this.selectedRowKeys = []
      this.selections = []
    },
    gotoDetail(record) {
      /* this.visible = false */
      /* this.$router.push({
        path: `/translate/mytasks/upload/${record.taskId}/${record.productionId}/${
          record.chapterId
        }?&record=${encodeURIComponent(
          JSON.stringify({ platformId: record.platformId, productionName: record.productName })
        )}&name=${record.productName + '-' + record.chapterOrder + '-' + record.stageName}&type=上传原稿`,
      }) */
      const newUrl = `/translate/mytasks/upload/${record.taskId}/${record.productionId}/${
        record.chapterId
      }?&record=${encodeURIComponent(
        JSON.stringify({ platformId: record.platformId, productionName: record.productName })
      )}&name=${record.productName + '-' + record.chapterOrder + '-' + record.stageName}&type=上传原稿`
      const a = document.createElement('a')
      a.href = newUrl
      a.target = '_blank'
      a.click()
      document.removeChild(a)
    }
  },
  watch: {
    /*     selectionRows() {
      this.dataSource = this.selectionRows.map(item => {
        return { ...item, jpgFileList: [], psdFileList: [] }
      })
    } */
  }
}
</script>

<style lang="less" scoped>
.disabled-up {
  cursor: not-allowed !important;
  color: rgba(0, 0, 0, 0.25);
}
/deep/ .up-msg-status .ant-btn {
  z-index: 0 !important;
}
.text-status .anticon-check-circle {
  color: var(--theme-color);
  position: relative;
  top: 1px;
}
.text-status .anticon-close-circle {
  color: red;
  position: relative;
  top: 1px;
}
.img-num-title {
  display: inline-block;
  width: 42px;
}
</style>
