var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-modal",
    {
      attrs: { width: "1420px", title: _vm.title, visible: _vm.visible },
      on: { cancel: _vm.handleCancel },
    },
    [
      _c("div", { staticClass: "actions flex jc-end mb-4" }),
      _c("a-table", {
        style: _vm.backCss,
        attrs: {
          "row-selection": {
            selectedRowKeys: _vm.selectedRowKeys,
            onChange: _vm.onSelectChange,
            columnWidth: 5,
          },
          columns: _vm.columns,
          rowKey: "id",
          customRow: _vm.clickRow,
          scroll: { x: "1380px", y: "500px" },
          "data-source": _vm.dataSource,
        },
        scopedSlots: _vm._u([
          {
            key: "taskName",
            fn: function (text, record) {
              return [
                _c("span", [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        record.productName +
                          "-" +
                          record.chapterOrder +
                          "-" +
                          record.taskName
                      ) +
                      "\n      "
                  ),
                ]),
              ]
            },
          },
          {
            key: "user",
            fn: function (text, record) {
              return [
                _c(
                  "div",
                  { staticStyle: { display: "flex", "align-items": "center" } },
                  [
                    _c("img", {
                      staticStyle: {
                        width: "30px",
                        height: "30px",
                        "border-radius": "50%",
                        "margin-right": "10px",
                      },
                      attrs: { src: record.avatar, alt: "" },
                    }),
                    _vm._v("\n        " + _vm._s(record.userName) + "\n      "),
                  ]
                ),
              ]
            },
          },
          {
            key: "completeStatus",
            fn: function (text, record) {
              return [
                _c(
                  "div",
                  {
                    staticClass: "up-msg-status",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                      },
                    },
                  },
                  [
                    _c("actions-tasks", {
                      attrs: {
                        propsParams: record,
                        isMyTask: true,
                        isMyTaskList: true,
                        isCalendar: true,
                        loadData: _vm.getTableData,
                      },
                      on: { openTaskModel: _vm.openTaskModel },
                    }),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "imgNum",
            fn: function (text, record) {
              return [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      (record.finishManuscriptList &&
                        record.finishManuscriptList.length) ||
                        0
                    )
                  ),
                ]),
              ]
            },
          },
          {
            key: "jd",
            fn: function (text, record) {
              return [
                _c(
                  "div",
                  [
                    _c("a-progress", {
                      attrs: {
                        percent: record.bottomProgress,
                        status: _vm.bottomStatus,
                      },
                    }),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "textStatus",
            fn: function (text, record) {
              return [
                _c(
                  "div",
                  { staticClass: "text-status" },
                  [
                    _c("a-icon", { attrs: { type: "check-circle" } }),
                    _c("span", { staticStyle: { "margin-left": "3px" } }, [
                      _vm._v("读取完成"),
                    ]),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "action",
            fn: function (text, record) {
              return [
                _c(
                  "div",
                  {
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                      },
                    },
                  },
                  [
                    _vm.sizeList.length
                      ? _c("defaultSizeUpload", {
                          ref: "defaultSizeUpload",
                          attrs: {
                            btnText: "上传完成稿",
                            upType: "JPG",
                            isTable: true,
                            showFileList: [...record.finishManuscriptList],
                            taskDetail: record,
                            btnType: "finish",
                            sizeList: _vm.sizeList,
                            loadData: _vm.getTableData,
                            saveUrl: "/finish/manuscript",
                          },
                          on: {
                            successProgress: (file) =>
                              _vm.successProgress(file, record),
                            startProgress: (
                              fileList,
                              finishFileList,
                              upType
                            ) => {
                              _vm.startProgress(
                                fileList,
                                finishFileList,
                                upType,
                                record
                              )
                            },
                            changeProgress: (
                              fileList,
                              finishFileList,
                              upType,
                              isSuccess
                            ) => {
                              _vm.changeProgress(
                                fileList,
                                finishFileList,
                                upType,
                                record,
                                isSuccess
                              )
                            },
                            changeTransProgress: (fileList, upType) => {
                              _vm.changeTransProgress(fileList, upType, record)
                            },
                            endProgress: (upType) => {
                              _vm.endProgress(upType, record)
                            },
                            replaceImg: _vm.replaceImg,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                      },
                    },
                  },
                  [
                    _vm.sizeList.length &&
                    record.departName.indexOf("日语部") > -1
                      ? _c("defaultSizeUpload", {
                          ref: "defaultSizeUpload",
                          attrs: {
                            btnText: "上传切片",
                            upType: "JPG",
                            isTable: true,
                            showFileList: [...record.finishManuscriptList],
                            taskDetail: record,
                            btnType: "finish",
                            sectionTotalSize: record.freeSectionMemory || 0,
                            sizeList: _vm.sizeList,
                            loadData: _vm.getTableData,
                            saveUrl: "/finish/manuscript",
                          },
                          on: {
                            successProgress: (file) =>
                              _vm.successProgress(file, record),
                            startProgress: (
                              fileList,
                              finishFileList,
                              upType
                            ) => {
                              _vm.startProgress(
                                fileList,
                                finishFileList,
                                upType,
                                record
                              )
                            },
                            changeProgress: (
                              fileList,
                              finishFileList,
                              upType,
                              isSuccess
                            ) => {
                              _vm.changeProgress(
                                fileList,
                                finishFileList,
                                upType,
                                record,
                                isSuccess
                              )
                            },
                            changeTransProgress: (fileList, upType) => {
                              _vm.changeTransProgress(fileList, upType, record)
                            },
                            endProgress: (upType) => {
                              _vm.endProgress(upType, record)
                            },
                            replaceImg: _vm.replaceImg,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                      },
                    },
                  },
                  [
                    _vm.sizeList.length &&
                    record.departName.indexOf("日语部") > -1
                      ? _c("defaultSizeUploadTmb", {
                          ref: "defaultSizeUploadTmb",
                          attrs: {
                            btnText: "上传tmb",
                            upType: "JPG",
                            isTable: true,
                            showFileList: [...record.finishManuscriptList],
                            taskDetail: record,
                            btnType: "finish",
                            sizeList: _vm.sizeList,
                            loadData: _vm.getTableData,
                            saveUrl: "/finish/manuscript",
                          },
                          on: {
                            successProgress: (file) =>
                              _vm.successProgress(file, record),
                            startProgress: (
                              fileList,
                              finishFileList,
                              upType
                            ) => {
                              _vm.startProgress(
                                fileList,
                                finishFileList,
                                upType,
                                record
                              )
                            },
                            changeProgress: (
                              fileList,
                              finishFileList,
                              upType,
                              isSuccess
                            ) => {
                              _vm.changeProgress(
                                fileList,
                                finishFileList,
                                upType,
                                record,
                                isSuccess
                              )
                            },
                            changeTransProgress: (fileList, upType) => {
                              _vm.changeTransProgress(fileList, upType, record)
                            },
                            endProgress: (upType) => {
                              _vm.endProgress(upType, record)
                            },
                            replaceImg: _vm.replaceImg,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _c(
        "template",
        { slot: "footer" },
        [
          _c("a-button", { key: "back", on: { click: _vm.handleCancel } }, [
            _vm._v("\n      关闭\n    "),
          ]),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }