var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-modal",
    {
      attrs: { width: "1520px", title: _vm.title, visible: _vm.visible },
      on: { cancel: _vm.handleCancel },
    },
    [
      _c(
        "div",
        { staticClass: "actions flex jc-end mb-4" },
        [
          _c(
            "a-dropdown-button",
            {
              staticClass: "mr-3",
              attrs: {
                disabled: !_vm.selectedRowKeys.length || _vm.btnLoadding,
                trigger: ["click"],
              },
              on: {
                click: function ($event) {
                  _vm.btnLoadding ? () => {} : _vm.translate(0)
                },
              },
            },
            [
              _c(
                "a-menu",
                { attrs: { slot: "overlay" }, slot: "overlay" },
                [
                  _c(
                    "a-menu-item",
                    {
                      key: "1",
                      on: {
                        click: function ($event) {
                          return _vm.translate(1)
                        },
                      },
                    },
                    [_vm._v(" 切换机翻 ")]
                  ),
                ],
                1
              ),
              _c(
                "span",
                [
                  _c("a-icon", { attrs: { type: "diff" } }),
                  _vm._v("\n        机翻\n      "),
                ],
                1
              ),
              _c("a-icon", {
                attrs: { slot: "icon", type: "down" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                  },
                },
                slot: "icon",
              }),
            ],
            1
          ),
          _c(
            "a-dropdown-button",
            {
              staticClass: "mr-3",
              attrs: {
                disabled: !_vm.selectedRowKeys.length || _vm.btnLoadding,
                type: "primary",
                spinning: _vm.btnLoadding,
                trigger: ["click"],
              },
              on: {
                click: function ($event) {
                  _vm.btnLoadding ? () => {} : _vm.ocr()
                },
              },
            },
            [
              _c(
                "a-menu",
                { attrs: { slot: "overlay" }, slot: "overlay" },
                [
                  _c("a-menu-item", { key: "2", on: { click: _vm.noOcr } }, [
                    _vm._v("清除结果"),
                  ]),
                ],
                1
              ),
              _c(
                "span",
                { on: { click: _vm.ocr } },
                [
                  _c("a-icon", { attrs: { type: "scan" } }),
                  _vm._v("\n        OCR\n      "),
                ],
                1
              ),
              _c("a-icon", {
                attrs: { slot: "icon", type: "down" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                  },
                },
                slot: "icon",
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("a-table", {
        style: _vm.backCss,
        attrs: {
          "row-selection": {
            selectedRowKeys: _vm.selectedRowKeys,
            onChange: _vm.onSelectChange,
            columnWidth: 60,
          },
          columns: _vm.columns,
          loading: _vm.btnLoadding,
          rowKey: "taskId",
          customRow: _vm.clickRow,
          scroll: { x: "1380px", y: "500px" },
          "data-source": _vm.dataSource,
        },
        scopedSlots: _vm._u([
          {
            key: "taskName",
            fn: function (text, record) {
              return [
                _c("span", [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        record.productName +
                          "-" +
                          record.chapterOrder +
                          "-" +
                          record.taskName
                      ) +
                      "\n      "
                  ),
                ]),
              ]
            },
          },
          {
            key: "user",
            fn: function (text, record) {
              return [
                _c(
                  "div",
                  { staticStyle: { display: "flex", "align-items": "center" } },
                  [
                    _c("img", {
                      staticStyle: {
                        width: "30px",
                        height: "30px",
                        "border-radius": "50%",
                        "margin-right": "10px",
                      },
                      attrs: { src: record.avatar, alt: "" },
                    }),
                    _vm._v("\n        " + _vm._s(record.userName) + "\n      "),
                  ]
                ),
              ]
            },
          },
          {
            key: "completeStatus",
            fn: function (text, record) {
              return [
                _c(
                  "div",
                  {
                    staticClass: "up-msg-status",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                      },
                    },
                  },
                  [
                    _c("actions-tasks", {
                      attrs: {
                        propsParams: record,
                        isMyTask: true,
                        isMyTaskList: true,
                        isCalendar: true,
                        loadData: _vm.getTableData,
                      },
                    }),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "imgNum",
            fn: function (text, record) {
              return [
                _c("div", [
                  _c("span", { staticClass: "img-num-title" }, [
                    _vm._v("原稿："),
                  ]),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        (record.manuscriptPSDList &&
                          record.manuscriptPSDList.length) ||
                          0
                      )
                    ),
                  ]),
                ]),
                _c("div", [
                  _c("span", { staticClass: "img-num-title" }, [
                    _vm._v("JPG："),
                  ]),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        (record.manuscriptJPGList &&
                          record.manuscriptJPGList.length) ||
                          0
                      )
                    ),
                  ]),
                ]),
              ]
            },
          },
          {
            key: "jd",
            fn: function (text, record) {
              return [
                _c(
                  "div",
                  [
                    _c("a-progress", {
                      attrs: {
                        percent: record.topProgress,
                        status: record.topStatus,
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("a-progress", {
                      attrs: {
                        percent: record.bottomProgress,
                        status: record.bottomStatus,
                      },
                    }),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "cg",
            fn: function (text, record) {
              return [
                _c("div", [_vm._v(_vm._s(record.cgTopNum))]),
                _c("div", [_vm._v(_vm._s(record.cgBottomNum))]),
              ]
            },
          },
          {
            key: "sb",
            fn: function (text, record) {
              return [
                _c("div", [_vm._v(_vm._s(record.sbTopNum))]),
                _c("div", [_vm._v(_vm._s(record.sbBottomNum))]),
              ]
            },
          },
          {
            key: "textStatus",
            fn: function (text, record) {
              return [
                _c("div", { staticClass: "text-status" }, [
                  record.hasOcr
                    ? _c(
                        "span",
                        [
                          _c("a-icon", { attrs: { type: "check-circle" } }),
                          _c(
                            "span",
                            { staticStyle: { "margin-left": "3px" } },
                            [_vm._v("完成")]
                          ),
                        ],
                        1
                      )
                    : _c(
                        "span",
                        [
                          _c("a-icon", { attrs: { type: "close-circle" } }),
                          _c(
                            "span",
                            { staticStyle: { "margin-left": "3px" } },
                            [_vm._v("未完成")]
                          ),
                        ],
                        1
                      ),
                ]),
              ]
            },
          },
          {
            key: "action",
            fn: function (text, record) {
              return [
                _c(
                  "div",
                  {
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                      },
                    },
                  },
                  [
                    _c("defaultSizeUpload", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.sizeList.length,
                          expression: "sizeList.length",
                        },
                      ],
                      ref: "defaultSizeUpload-PSD-" + record.taskId,
                      attrs: {
                        btnText: "上传原稿",
                        isTable: true,
                        upType: "PSD",
                        taskDetail: record,
                        showFileList: record.manuscriptPSDList || [],
                        btnType: "original",
                        sizeList: _vm.sizeList,
                        loadData: () => {},
                        saveUrl: "/kt/translate/uploadOriginalManuscript",
                      },
                      on: {
                        successProgress: (file) =>
                          _vm.successProgress(file, record, "PSD"),
                        startProgress: (fileList, finishFileList, upType) => {
                          _vm.startProgress(
                            fileList,
                            finishFileList,
                            upType,
                            record
                          )
                        },
                        changeProgress: (
                          fileList,
                          finishFileList,
                          upType,
                          isSuccess
                        ) => {
                          _vm.changeProgress(
                            fileList,
                            finishFileList,
                            upType,
                            record,
                            isSuccess
                          )
                        },
                        changeTransProgress: (fileList, upType) => {
                          _vm.changeTransProgress(fileList, upType, record)
                        },
                        endProgress: (upType) => {
                          _vm.endProgress(upType, record)
                        },
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                      },
                    },
                  },
                  [
                    _c("defaultSizeUpload", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.sizeList.length,
                          expression: "sizeList.length",
                        },
                      ],
                      ref: "defaultSizeUpload-JPG-" + record.taskId,
                      attrs: {
                        btnText: "上传JPG",
                        isTable: true,
                        upType: "JPG",
                        taskDetail: record,
                        showFileList: record.manuscriptJPGList || [],
                        btnType: "original",
                        sizeList: _vm.sizeList,
                        loadData: () => {},
                        saveUrl: "/kt/translate/uploadOriginalManuscript",
                      },
                      on: {
                        successProgress: (file) =>
                          _vm.successProgress(file, record, "JPG"),
                        startProgress: (fileList, finishFileList, upType) => {
                          _vm.startProgress(
                            fileList,
                            finishFileList,
                            upType,
                            record
                          )
                        },
                        changeProgress: (
                          fileList,
                          finishFileList,
                          upType,
                          isSuccess
                        ) => {
                          _vm.changeProgress(
                            fileList,
                            finishFileList,
                            upType,
                            record,
                            isSuccess
                          )
                        },
                        changeTransProgress: (fileList, upType) => {
                          _vm.changeTransProgress(fileList, upType, record)
                        },
                        endProgress: (upType) => {
                          _vm.endProgress(upType, record)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _c(
        "template",
        { slot: "footer" },
        [
          _c("a-button", { key: "back", on: { click: _vm.handleCancel } }, [
            _vm._v(" 关闭 "),
          ]),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }