<template>
  <a-modal width="1420px" :title="title" :visible="visible" @cancel="handleCancel">
    <div class="actions flex jc-end mb-4">
      <!--       <a-button icon="scan"  type="primary">
        OCR
      </a-button> -->
      <!--       <a-button type="primary">
        <span class="iconfont icon-duohangwenben icon"></span>
        读取
      </a-button> -->
    </div>
    <a-table
      :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange, columnWidth: 5 }"
      :columns="columns"
      :style="backCss"
      rowKey="id"
      :customRow="clickRow"
      :scroll="{ x: '1380px', y: '500px' }"
      :data-source="dataSource"
    >
      <template slot="taskName" slot-scope="text, record">
        <span>
          {{ record.productName + '-' + record.chapterOrder + '-' + record.taskName }}
        </span>
      </template>
      <template slot="user" slot-scope="text, record">
        <div style="display: flex;align-items: center;">
          <img style="width:30px;height:30px;border-radius:50%;margin-right:10px;" :src="record.avatar" alt="" />
          {{ record.userName }}
        </div>
      </template>
      <template slot="completeStatus" slot-scope="text, record">
        <div @click.stop class="up-msg-status">
          <actions-tasks
            :propsParams="record"
            :isMyTask="true"
            :isMyTaskList="true"
            :isCalendar="true"
            :loadData="getTableData"
            @openTaskModel="openTaskModel"
          ></actions-tasks>
        </div>
      </template>
      <template slot="imgNum" slot-scope="text, record">
        <span>{{ (record.finishManuscriptList && record.finishManuscriptList.length) || 0 }}</span>
      </template>
      <template slot="jd" slot-scope="text, record">
        <div><a-progress :percent="record.bottomProgress" :status="bottomStatus" /></div>
      </template>
      <template slot="textStatus" slot-scope="text, record">
        <div class="text-status"><a-icon type="check-circle" /><span style="margin-left:3px">读取完成</span></div>
      </template>
      <template slot="action" slot-scope="text, record">
        <div @click.stop>
          <defaultSizeUpload
            v-if="sizeList.length"
            btnText="上传完成稿"
            upType="JPG"
            :isTable="true"
            :showFileList="[...record.finishManuscriptList]"
            :taskDetail="record"
            @successProgress="file => successProgress(file, record)"
            @startProgress="
              (fileList, finishFileList, upType) => {
                startProgress(fileList, finishFileList, upType, record)
              }
            "
            @changeProgress="
              (fileList, finishFileList, upType, isSuccess) => {
                changeProgress(fileList, finishFileList, upType, record, isSuccess)
              }
            "
            @changeTransProgress="
              (fileList, upType) => {
                changeTransProgress(fileList, upType, record)
              }
            "
            @endProgress="
              upType => {
                endProgress(upType, record)
              }
            "
            btnType="finish"
            @replaceImg="replaceImg"
            :sizeList="sizeList"
            :loadData="getTableData"
            saveUrl="/finish/manuscript"
            ref="defaultSizeUpload"
          />
        </div>
        <div @click.stop>
          <defaultSizeUpload
            v-if="sizeList.length && record.departName.indexOf('日语部') > -1"
            btnText="上传切片"
            upType="JPG"
            :isTable="true"
            :showFileList="[...record.finishManuscriptList]"
            :taskDetail="record"
            @successProgress="file => successProgress(file, record)"
            @startProgress="
              (fileList, finishFileList, upType) => {
                startProgress(fileList, finishFileList, upType, record)
              }
            "
            @changeProgress="
              (fileList, finishFileList, upType, isSuccess) => {
                changeProgress(fileList, finishFileList, upType, record, isSuccess)
              }
            "
            @changeTransProgress="
              (fileList, upType) => {
                changeTransProgress(fileList, upType, record)
              }
            "
            @endProgress="
              upType => {
                endProgress(upType, record)
              }
            "
            btnType="finish"
            @replaceImg="replaceImg"
            :sectionTotalSize="record.freeSectionMemory || 0"
            :sizeList="sizeList"
            :loadData="getTableData"
            saveUrl="/finish/manuscript"
            ref="defaultSizeUpload"
          />
        </div>
        <div @click.stop>
          <defaultSizeUploadTmb
            v-if="sizeList.length && record.departName.indexOf('日语部') > -1"
            btnText="上传tmb"
            ref="defaultSizeUploadTmb"
            upType="JPG"
            :isTable="true"
            :showFileList="[...record.finishManuscriptList]"
            :taskDetail="record"
            @successProgress="file => successProgress(file, record)"
            @startProgress="
              (fileList, finishFileList, upType) => {
                startProgress(fileList, finishFileList, upType, record)
              }
            "
            @changeProgress="
              (fileList, finishFileList, upType, isSuccess) => {
                changeProgress(fileList, finishFileList, upType, record, isSuccess)
              }
            "
            @changeTransProgress="
              (fileList, upType) => {
                changeTransProgress(fileList, upType, record)
              }
            "
            @endProgress="
              upType => {
                endProgress(upType, record)
              }
            "
            btnType="finish"
            @replaceImg="replaceImg"
            :sizeList="sizeList"
            :loadData="getTableData"
            saveUrl="/finish/manuscript"
          />
        </div>
      </template>
    </a-table>
    <template slot="footer">
      <a-button key="back" @click="handleCancel">
        关闭
      </a-button>
    </template>
  </a-modal>
</template>

<script>
import ActionsTasks from '@/views/system/modules/mytasks/actionsTasksBtn'
import { UploadFileToOssMixins } from '@/mixins/UploadFileToOssMixins'
import defaultSizeUpload from '@/components/default-size-upload'
import defaultSizeUploadTmb from '@/components/default-size-upload-tmb'
import { postAction, getAction } from '@/api/manage'
import { handleArrayParams } from '@/utils/util'
import { upload } from '@/utils/upload'
import { simpleDebounce } from '@/utils/util'
export default {
  mixins: [UploadFileToOssMixins],
  props: {
    selectionRows: {
      type: Array,
      default: () => []
    },
    departName: {
      type: String,
      default: '123'
    },
    title: {
      type: String,
      default: '上传原稿'
    }
  },
  components: {
    ActionsTasks,
    defaultSizeUpload,
    defaultSizeUploadTmb
  },
  data() {
    return {
      visible: false,
      loading: false,
      dataSource: [],
      sizeList: [],
      columns: [
        {
          title: '任务名称',
          dataIndex: 'taskName',
          scopedSlots: { customRender: 'taskName' },
          width: 15
        },
        {
          title: '人员',
          dataIndex: 'user',
          scopedSlots: { customRender: 'user' },
          width: 10
        },
        {
          title: '完成状态',
          dataIndex: 'completeStatus',
          scopedSlots: { customRender: 'completeStatus' },
          width: 10
        },
        {
          title: '图片数量',
          dataIndex: 'imgNum',
          scopedSlots: { customRender: 'imgNum' },
          width: 10
        },
        {
          title: '进度',
          dataIndex: 'jd',
          scopedSlots: { customRender: 'jd' },
          width: 10
        },
        /*         {
          title: '成功',
          dataIndex: 'cg',
          scopedSlots: { customRender: 'cg' },
          width: 100
        },
        {
          title: '失败',
          dataIndex: 'sb',
          scopedSlots: { customRender: 'sb' },
          width: 100
        }, */
        /*         {
          title: '文字状态',
          dataIndex: 'textStatus',
          scopedSlots: { customRender: 'textStatus' },
          width: 180
        }, */
        {
          title: '修改时间',
          dataIndex: 'lastModified',
          width: 10
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
          width: 10
        }
      ],
      clickRow: (record, index) => ({
        on: {
          click: () => {
            this.gotoDetail(record)
          }
        }
      }),
      color: '',
      selectedRowKeys: [],
      sectionTotalSize: 0,
      bottomProgress: 0,
      topProgress: 0,
      uploadFileList: [],
      copyRows: [],
      topStatus: 'active',
      bottomStatus: 'active'
    }
  },
  created() {},
  mounted() {
    setTimeout(() => {
      for (let css of document.styleSheets) {
        for (let rule of css.cssRules) {
          if (rule.cssText?.includes('.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected')) {
            this.color = rule.style.backgroundColor
          }
        }
      }
    }, 100)
  },
  computed: {
    backCss() {
      let color = this.color
      let backColor = this.$store.state.app.color
      return {
        '--back-color': color,
        '--theme-color': backColor
      }
    }
  },
  methods: {
    async getFileLength(record) {
      const res = await postAction('/finish/manuscript/sum/file/length', [record.chapterId])
      if (res.code == 200) {
        this.sectionTotalSize = res.data[record.chapterId] || 0
      }
    },
    openTaskModel(data) {
      this.$emit('openTaskModel', data)
    },
    async replaceImg(id, file, files, data) {
      let splicingManuscriptList = []
      files?.forEach(c => {
        splicingManuscriptList.push({
          fileName: c.fileName,
          filePath: c.filePath,
          dpi: c.dpi,
          height: c.height,
          width: c.width,
          fileLength: c.fileLength
        })
      })
      const res = await postAction('/finish/manuscript/replace', {
        chapterId: data.chapterId,
        taskId: data.taskId,
        replaceId: id,
        finishManuscript: {
          fileName: file.fileName,
          filePath: file.filePath,
          dpi: file.dpi,
          height: file.height,
          width: file.width,
          fileLength: file.fileLength,
          fileSize: file.fileSize ? file.fileSize : '',
          splicingManuscriptList
        },
        stageName: this.selectionRows.length ? this.selectionRows[0].stageName : ''
      })
      if (res.code == 200) {
        this.getTableData()
      }
    },
    startProgress(fileList, finishFileList, upType, record) {
      if (!fileList.length) return
      if (upType == 'JPG') {
        record.bottomStatus = 'active'
        record.bottomProgress = 0
        record.rightTransNum = 1
        if (record.bottomProgress < 80) return
        record.bottomProgress = ((finishFileList.length / fileList.length) * 20).toFixed(0) - 0 + 80
      } else {
        record.topStatus = 'active'
        record.topProgress = 0
        record.leftTransNum = 1
        if (record.topProgress < 80) return
        record.topProgress = ((finishFileList.length / fileList.length) * 20).toFixed(0) - 0 + 80
      }
    },
    changeProgress(fileList, finishFileList, upType, record, isSuccess) {
      if (!fileList.length) return
      if (!isSuccess) {
        if (upType == 'PSD') {
          record.sbTopNum++
        } else {
          record.sbBottomNum++
        }
        return
      }
      if (upType == 'JPG') {
        if (record.bottomProgress < 80) return
        record.bottomProgress = ((finishFileList.length / fileList.length) * 20).toFixed(0) - 0 + 80
      } else {
        if (record.topProgress < 80) return
        record.topProgress = ((finishFileList.length / fileList.length) * 20).toFixed(0) - 0 + 80
      }
    },
    changeTransProgress(fileList, upType, record) {
      if (!fileList.length) return
      if (upType == 'JPG') {
        record.bottomProgress = ((record.rightTransNum / fileList.length) * 80).toFixed(0) - 0
        record.rightTransNum++
        if (record.bottomProgress > 78) {
          record.bottomProgress = 80
        }
      } else {
        record.topProgress = ((record.leftTransNum / fileList.length) * 80).toFixed(0) - 0
        record.leftTransNum++
        if (record.topProgress > 78) {
          record.topProgress = 80
        }
      }
    },
    endProgress(upType, record) {
      if (upType == 'JPG') {
        /*         if (record.originalManuscriptJPGStatus && record.originalManuscriptJPGStatus.failedCount) {
          record.bottomStatus = 'exception'
          record.bottomProgress = 99
        } else {
          record.bottomStatus = 'success'
          record.bottomProgress = 100
        } */
        record.bottomStatus = 'success'
        record.cgBottomNum++
        record.bottomProgress = 100
      } else {
        /*  if (record.originalManuscriptPSDStatus && record.originalManuscriptPSDStatus.failedCount) {
          record.topStatus = 'exception'
          record.topProgress = 99
        } else {
          record.topStatus = 'success'
          record.topProgress = 100
        } */
        record.topStatus = 'success'
        record.cgTopNum++
        record.topProgress = 100
      }
      /*       setTimeout(() => {
        if(this.dataSource.every(item=>!item.loading)){
          this.getTableData()
        }
      }, 1000) */
    },
    successProgress(files, record) {
      files?.forEach(file=>{
        record.finishManuscriptList.push(file)
      })
      record.topProgress = 0
      record.bottomProgress = 0
      record.topStatus = 'active'
      record.bottomStatus = 'active'
      record.rightTransNum = 1
      record.leftTransNum = 1
      record.cgTopNum = 0
      record.cgBottomNum = 0
      record.sbTopNum = 0
      record.sbBottomNum = 0
    },
    changeLoading: simpleDebounce(function() {
      this.changeLoadingSim()
    }, 2000),
    changeLoadingSim() {
      this.loading = false
    },
    async getTableData() {
      let params = []
      this.copyRows.forEach(item => {
        if (params.every(p => p.productionId != item.productionId)) {
          params.push({
            productionId: item.productionId,
            chapterId: [],
            taskIds: []
          })
        }
      })
      this.copyRows.forEach(item => {
        params.forEach(p => {
          if (item.productionId == p.productionId) {
            p.chapterId.push(item.chapterId)
            p.taskIds.push(item.taskId || item.id)
          }
        })
      })
      this.loading = true
      const rest = await postAction('/finish/manuscript/upload/list', params)
      this.changeLoading()
      if (rest.code == 200) {
        this.dataSource = rest.data.map(item => {
          return {
            ...item.task,
            stageName: item.task.taskName,
            id: item.id,
            taskStatus:
              item.task.taskStatus == 'STARTED'
                ? '可开始'
                : item.task.taskStatus == 'REJECTING'
                ? '驳回'
                : item.task.taskStatus == 'WAITING'
                ? '等待'
                : item.task.taskStatus == 'FINISHED'
                ? '已完成'
                : '',
            productName: item.production.productionName,
            freeSectionMemory: item.freeSectionMemory - 0 || 0,
            topProgress: 0,
            bottomProgress: 0,
            topStatus: 'active',
            bottomStatus: 'active',
            rightTransNum: 1,
            leftTransNum: 1,
            cgTopNum: 0,
            cgBottomNum: 0,
            sbTopNum: 0,
            sbBottomNum: 0,
            finishManuscriptList: item.finishManuscriptList,
            lastModified: item.finishManuscriptList.length
              ? item.finishManuscriptList[item.finishManuscriptList.length - 1].gmtCreate
              : '',
            userName: item.user.nickName,
            avatar: item.user.avatar
          }
        })
        if (this.sizeList.length) {
          this.defaultMaxHeight = this.sizeList[0].size.jpgMaxHeight
          this.defaultMinHeight = this.sizeList[0].size.jpgMinHeight
          this.defaultWidth = this.sizeList[0].size.jpgWidth
          this.defaultType = this.sizeList[0].size.jpgFormat
          this.defaultDpi = this.sizeList[0].size.jpgResolution
          setTimeout(() => {
            if (this.$refs.defaultSizeUpload) {
              this.$refs.defaultSizeUpload.defaultType = this.defaultType
            }
            if (this.$refs.defaultSizeUploadTmb) {
              this.$refs.defaultSizeUploadTmb.defaultType = this.sizeList[0].size.tmbFormat
            }
          }, 500)
        }
      }
    },
    formatDate(dateIn) {
      var y = dateIn.getFullYear()
      var m = dateIn.getMonth() + 1
      var d = dateIn.getDate()
      return y + (m < 10 ? '-0' : '-') + m + (d < 10 ? '-0' : '-') + d
    },
    handleUpload(options, type, record, upType) {
      /*       if (upType == 'original') {
        this.topProgress = 0
      } else {
        this.bottomProgress = 0
      } */
      let formData = new FormData(),
        file = options.file
      for (let key in this.ossUploadData) {
        formData.append(key, this.ossUploadData[key])
      }
      formData.append('file', file)
      let index = this.uploadFileList.push(file) - 1
      upload(this.ossUploadData.host, formData, percent => this.setUploadProcess(percent, file, index, upType)).then(
        async () => {
          //成功处理
          Object.assign(file, { status: 'done' })
          this.uploadFileList.splice(index, 1, file)
          let params = this.getSaveFileParams(file)
          console.log(file)
          let that = this
          var read = new FileReader()
          read.readAsDataURL(file)
          read.onload = function(e) {
            var img = new Image()
            img.src = e.target.result
            img.onload = async function() {
              // 默认按比例压缩
              let data = {
                chapterId: record.chapterId,
                dataList: [
                  {
                    fileName: file.name,
                    filePath: params.filePath,
                    fileType: type,
                    wasSuccess: true,
                    height: this.height,
                    width: this.width,
                    fileLength: file.size,
                    lastModified: that.formatDate(file.lastModifiedDate)
                  }
                ]
              }
              const res = await postAction('/kt/translate/uploadOriginalManuscript', data)
              if (res.code == 200) {
                if (type == 'JPG') {
                  if (record.originalManuscriptJPGStatus && record.originalManuscriptJPGStatus.successCount) {
                    record.originalManuscriptJPGStatus.successCount =
                      record.originalManuscriptJPGStatus.successCount + 1
                  } else {
                    record.originalManuscriptJPGStatus.successCount = 1
                  }
                  if (record.originalManuscriptJPGStatus && record.originalManuscriptJPGStatus.count) {
                    record.originalManuscriptJPGStatus.count = record.originalManuscriptJPGStatus.count + 1
                  } else {
                    record.originalManuscriptJPGStatus.count = 1
                  }
                } else {
                  if (record.originalManuscriptPSDStatus && record.originalManuscriptPSDStatus.successCount) {
                    record.originalManuscriptPSDStatus.successCount =
                      record.originalManuscriptPSDStatus.successCount + 1
                  } else {
                    record.originalManuscriptPSDStatus.successCount = 1
                  }
                  if (record.originalManuscriptPSDStatus && record.originalManuscriptPSDStatus.count) {
                    record.originalManuscriptPSDStatus.count = record.originalManuscriptPSDStatus.count + 1
                  } else {
                    record.originalManuscriptPSDStatus.count = 1
                  }
                }
                if (upType == 'original') {
                  that.topProgress = 100
                  that.topStatus = 'success'
                } else {
                  that.bottomProgress = 100
                  that.bottomStatus = 'success'
                }
                that.$message.success(file.name + '上传成功')
              }
              that.removeOssFilePolicy(file)
              that.avatarLoading = false
            }
          }
        },
        err => {
          //失败处理
          Object.assign(file, { status: 'error', message: '上传失败' })
          this.uploadFileList.splice(index, 1, file)
          if (type == 'JPG') {
            if (record.originalManuscriptJPGStatus && record.originalManuscriptJPGStatus.failedCount) {
              record.originalManuscriptJPGStatus.failedCount = record.originalManuscriptJPGStatus.failedCount + 1
            } else {
              record.originalManuscriptJPGStatus.failedCount = 1
            }
          } else {
            if (record.originalManuscriptPSDStatus && record.originalManuscriptPSDStatus.failedCount) {
              record.originalManuscriptPSDStatus.failedCount = record.originalManuscriptPSDStatus.failedCount + 1
            } else {
              record.originalManuscriptPSDStatus.failedCount = 1
            }
          }
          if (upType == 'original') {
            this.topProgress = 99
            this.topStatus = 'exception'
          } else {
            this.bottomProgress = 99
            this.bottomStatus = 'exception'
          }
        }
      )
    },
    setUploadProcess(percent, file, index, upType) {
      Object.assign(file, { percent })
      this.uploadFileList.splice(index, 1, file)
      let num = 0
      this.uploadFileList.forEach(item => {
        num = num + item.percent
      })
      if (upType == 'original') {
        let progres = (num / this.uploadFileList.length).toFixed(2) - 0
        if (progres > 99 || progres == 100) {
          progres = 99
        }
        this.topProgress = progres
      } else {
        let progres = (num / this.uploadFileList.length).toFixed(2) - 0
        if (progres > 99 || progres == 100) {
          progres = 99
        }
        this.bottomProgress = progres
      }
    },
    async handleChangeUpload(info, type, record) {
      if (info.file.status === 'uploading') {
        this.avatarLoading = true
        return
      }
      if (info.file.status === 'done') {
      }
    },
    async beforeRightUpload(file) {
      return this.ossPolicy(file.name)
    },
    ossPolicy(fileName) {
      let _this = this
      return this.tokenAxios({
        url: '/oss',
        method: 'get'
      }).then(res => {
        let policy = {
          policy: res.data.policy,
          signature: res.data.signature,
          ossaccessKeyId: res.data.accessid,
          dir: res.data.dir,
          host: res.data.host,
          key: `${res.data.dir}${this.getGuid()}_${fileName.replace(/[^\u4e00-\u9fa5a-zA-Z0-9\w]/g, '')}`
        }
        _this.ossUploadData = policy
        _this.ossFilePolicy[fileName] = policy
      })
    },
    getGuid() {
      return `${this.S4() + this.S4()}-${this.S4()}-${this.S4()}-${this.S4()}-${this.S4()}${this.S4()}${this.S4()}`
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    async open() {
      this.visible = true
      /*       this.selectionRows.forEach(item => {
        if (item.originalManuscriptPSDStatus && item.originalManuscriptPSDStatus.failedCount) {
          item.originalManuscriptPSDStatus.failedCount = 0
        }
        if (item.originalManuscriptJPGStatus && item.originalManuscriptJPGStatus.failedCount) {
          item.originalManuscriptJPGStatus.failedCount = 0
        }
        item.topProgress = 0
        item.bottomProgress = 0
        item.rightTransNum = 1
        item.leftTransNum = 1
        item.topStatus = 'active'
        item.bottomStatus = 'active'
      }) */
      this.copyRows = JSON.parse(JSON.stringify(this.selectionRows))
      this.getTableData()
      const res = await getAction(
        '/kt/translate/manuscript/specification?' +
          new URLSearchParams(
            handleArrayParams({ productIds: this.selectionRows.map(item => item.productionId) })
          ).toString()
      )
      if (res.code == 200) {
        this.sizeList = res.data
        if (!this.sizeList.length) {
          this.$message.error('未获取到默认尺寸，无法上传图片')
        } else {
          this.defaultMaxHeight = this.sizeList[0].size.jpgMaxHeight
          this.defaultMinHeight = this.sizeList[0].size.jpgMinHeight
          this.defaultWidth = this.sizeList[0].size.jpgWidth
          this.defaultType = this.sizeList[0].size.jpgFormat
          this.defaultDpi = this.sizeList[0].size.jpgResolution
          setTimeout(() => {
            if (this.$refs.defaultSizeUpload) {
              this.$refs.defaultSizeUpload.defaultType = this.defaultType
            }
            if (this.$refs.defaultSizeUploadTmb) {
              this.$refs.defaultSizeUploadTmb.defaultType = this.sizeList[0].size.tmbFormat
            }
          }, 500)
        }
      } else {
        this.$message.error('未获取到默认尺寸，无法上传图片')
      }
    },
    async handleOk() {
      this.visible = false
    },
    handleCancel() {
      this.visible = false
      this.bottomProgress = 0
      this.topProgress = 0
      this.getTableData()
    },
    gotoDetail(record) {
      /*       this.visible = false
      this.$router.push({
        path: `/translate/mytasks/upload/${record.taskId}/${record.productionId}/${
          record.chapterId
        }?&record=${encodeURIComponent(
          JSON.stringify({ platformId: record.platformId, productionName: record.productName })
        )}&name=${record.productName + '-' + record.chapterOrder + '-' + record.stageName}&type=上传完成稿`
      }) */
      const newUrl = `/translate/mytasks/upload/${record.taskId}/${record.productionId}/${
        record.chapterId
      }?&record=${encodeURIComponent(
        JSON.stringify({ platformId: record.platformId, productionName: record.productName })
      )}&name=${record.productName + '-' + record.chapterOrder + '-' + record.stageName}&type=上传完成稿`
      const a = document.createElement('a')
      a.href = newUrl
      a.target = '_blank'
      a.click()
      document.removeChild(a)
    }
  },
  watch: {
    selectionRows() {
      /*       this.dataSource = this.selectionRows.map(item => {
        return { ...item, jpgFileList: [], psdFileList: [] }
      }) */
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .ant-table-body {
  overflow: auto !important;
}
/deep/ .up-msg-status .ant-btn {
  z-index: 0 !important;
}
.text-status .anticon-check-circle {
  color: var(--theme-color);
  position: relative;
  top: 1px;
}
.img-num-title {
  display: inline-block;
  width: 42px;
}
</style>
